import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Grid, Container } from '@mui/material';
import Footer from 'src/components/Footer';
// import Tickets from './Tickets';
import { useEffect, useState } from 'react';
import EventService from 'src/services/EventService';
import { useParams } from 'react-router';
import TransactionOrderTable from '../Transactions/TransactionOrderTable';

function EventTransactions() {
  const { eventId } = useParams();
  const [transactionData, settransactionData] = useState<any[]>([]);
  const [isTransactionDataLoading, setIsTransactionDataLoading] =
    useState<boolean>(false);

  useEffect(() => {
    try {
      setIsTransactionDataLoading(true);
      EventService.getTransactionsByEventId(eventId).then((response) => {
        setIsTransactionDataLoading(false);
        if (response?.status === 200) {
          settransactionData(response?.data?.event_transactions);
          console.log('response?.data', response?.data?.event_transactions);
        }
      });
    } catch (error) {
      setIsTransactionDataLoading(false);
      console.error(error);
    }
  }, [eventId]);

  return (
    <>
      <Helmet>
        <title>Transactions - Event</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader eventId={eventId} />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <TransactionOrderTable
              tickets={transactionData}
              isLoading={isTransactionDataLoading}
            />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default EventTransactions;
