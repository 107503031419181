import { Box, Skeleton } from '@mui/material';
import React from 'react';

function TableRowSkeleton() {
  const squareSize = 80;
  return (
    <div className="w-[98%] mx-auto flex">
      <Box sx={{ width: 1 }} className="">
        {/* <Skeleton
          variant="rectangular"
          width={squareSize}
          height={squareSize}
        /> */}
        <Skeleton height={50} />
        {/* <Skeleton animation="wave" height={30} /> */}
        {/* <Skeleton animation={false} /> */}
      </Box>
    </div>
  );
}

export default TableRowSkeleton;
