import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import EventTransactions from './content/applications/EventTransactions';
// import RefundedTickets from './content/applications/EventDetails/RefundedTickets';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Overview = Loader(lazy(() => import('src/content/overview')));

// Dashboards

const Crypto = Loader(lazy(() => import('src/content/dashboards/Crypto')));

// Applications

const Events = Loader(lazy(() => import('src/content/applications/Events')));
const EventDetails = Loader(
  lazy(() => import('src/content/applications/EventDetails'))
);
const Transactions = Loader(
  lazy(() => import('src/content/applications/Transactions'))
);

const AccessDashboard = Loader(
  lazy(() => import('src/content/dashboards/Access'))
);

const Managers = Loader(
  lazy(() => import('src/content/applications/Managers'))
);
const ManagerEvent = Loader(
  lazy(() => import('src/content/applications/Managers/events'))
);
const ManageAllEvent = Loader(
  lazy(() => import('src/content/applications/Managers/AllEvents'))
);
const ManagerEventDetails = Loader(
  lazy(() => import('src/content/applications/Managers/eventDetails'))
);
const ManagerEventTransDetails = Loader(
  lazy(() => import('src/content/applications/Managers/eventTransactions'))
);
const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings'))
);

// Components

const Buttons = Loader(
  lazy(() => import('src/content/pages/Components/Buttons'))
);
const Modals = Loader(
  lazy(() => import('src/content/pages/Components/Modals'))
);
const Accordions = Loader(
  lazy(() => import('src/content/pages/Components/Accordions'))
);
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
const Badges = Loader(
  lazy(() => import('src/content/pages/Components/Badges'))
);
const Tooltips = Loader(
  lazy(() => import('src/content/pages/Components/Tooltips'))
);
const Avatars = Loader(
  lazy(() => import('src/content/pages/Components/Avatars'))
);
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const Login = Loader(lazy(() => import('src/content/Logins/LoginPage')));
const Register = Loader(lazy(() => import('src/content/Logins/RegisterPage')));
const ForgotPassword = Loader(
  lazy(() => import('src/content/Logins/ForgotPassword'))
);
const ResetPassword = Loader(
  lazy(() => import('src/content/Logins/ResetPassword'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Login to="/login" replace />
      },
      {
        path: '/register',
        element: <Register to="/login" replace />
      },
      {
        path: '/forgot-password',
        element: <ForgotPassword to="/forgot-password" replace />
      },
      {
        path: '/reset-password',
        element: <ResetPassword to="/forgot-password" replace />
      },

      // {
      //   path: '/dashboards/ppv',
      //   element: <Navigate to="/dashboards/ppv" replace />
      // },
      // {
      //   path: '/',
      //   element: <Navigate to="/" replace />
      // },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'dashboards',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="ppv" replace />
      },
      {
        path: 'ppv',
        element: <Crypto />
      }
      // {
      //   path: 'events',
      //   element: <Events />
      // }
    ]
  },
  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="transactions" replace />
      },
      {
        path: 'transactions',
        element: <Transactions />
      },
      {
        path: 'events',
        element: <Events />
      },
      {
        path: 'event/:eventId/details/',
        element: <EventDetails />
      },
      {
        path: 'event/:eventId/transactions/',
        element: <EventTransactions />
      },
      // {
      //   path: 'event/refunded',
      //   element: <RefundedTickets />
      // },
      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <Navigate to="details" replace />
          },
          // {
          //   path: 'details',
          //   element: <UserProfile />
          // },
          {
            path: 'settings',
            element: <UserSettings />
          }
        ]
      }
    ]
  },
  {
    path: 'access',
    element: <SidebarLayout />,
    children: [
      {
        path: 'dashboard',
        element: <AccessDashboard />
      },
      {
        path: 'managers',
        element: <Managers />
      },
      {
        path: 'events',
        element: <ManageAllEvent />
      },
      {
        path: 'manager/:managerId/events',
        element: <ManagerEvent />
      },
      {
        path: 'manager-event/:eventId',
        element: <ManagerEventDetails />
      },
      {
        path: 'manager/1/event/1/transactions',
        element: <ManagerEventTransDetails />
      }
    ]
  },
  {
    path: '/components',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />
      },
      {
        path: 'buttons',
        element: <Buttons />
      },
      {
        path: 'modals',
        element: <Modals />
      },
      {
        path: 'accordions',
        element: <Accordions />
      },
      {
        path: 'tabs',
        element: <Tabs />
      },
      {
        path: 'badges',
        element: <Badges />
      },
      {
        path: 'tooltips',
        element: <Tooltips />
      },
      {
        path: 'avatars',
        element: <Avatars />
      },
      {
        path: 'cards',
        element: <Cards />
      },
      {
        path: 'forms',
        element: <Forms />
      }
    ]
  }
];

export default routes;
