import axios from "axios";
import authHeader from "../utils/auth-header";

const baseURL = process.env.REACT_APP_BACKEND_SERVER;
const originURL = process.env.REACT_APP_API_ORIGIN;


const login = async (data: any) => {
    const response = await axios({
      method: "post",
      data: data,
      url: `${baseURL}/ppv/login`,
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    });
  
    return response;
  };

  const logout = async () => {
    localStorage.removeItem("persist:root");
  };

  const AuthService = {
    login,
    logout
  };
  
  export default AuthService;
  