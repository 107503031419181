import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    version: 1,
    currentUser: null,
    isFetching: false,
    error: false,
  };

  const userSlice = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {
      loginStart: (state: any) => {
        state.isFetching = true;
      },
      loginSuccess: (state: any, action: any) => {
        state.isFetching = false;
        state.currentUser = action.payload;
        state.error = false;
      },
      loginFailure: (state: any) => {
        state.isFetching = false;
        state.error = true;
      },
      logout: (state: any) => {
        if (state.version !== initialState.version) {
          return initialState;
        }
        localStorage.removeItem("persist:root");
        state.currentUser = null;
      },
    },
  });
  
  export const {
    loginStart,
    loginSuccess,
    loginFailure,
    logout,
  } = userSlice.actions;
  export default userSlice.reducer;