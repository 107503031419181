import { Typography, Button, Grid, IconButton } from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { ArrowBack } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import EventService from 'src/services/EventService';
import { IPPVEvent } from 'src/models/IPPVEvent';

function PageHeader({ eventId }) { // Destructure eventId from props
  const user = {
    name: 'Harsha Gunathilake',
    avatar: '/static/images/avatars/3.jpg'
  };

  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigate = () => {
    navigate(`/management/event/${eventId}/details`); // Now eventId is correctly passed
  };

  const [eventData, setEventData] = useState<IPPVEvent | null>(null);

  useEffect(() => {
    try {
      EventService.getEventByEventId(eventId).then((response) => {
        if (response?.status === 200) {
          setEventData(response?.data?.ppv_event);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }, [eventId]);

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          <IconButton
            aria-label="delete"
            sx={{ margin: 1 }}
            size="small"
            onClick={handleNavigate}
          >
            <ArrowBack fontSize="inherit" />
          </IconButton>
          {eventData?.title} Event Transactions
        </Typography>
      </Grid>
    </Grid>
  );
}

export default PageHeader;
