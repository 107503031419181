import { Box, Skeleton } from '@mui/material';
import React from 'react';
import TableRowSkeleton from './TableRowSkeleton';

function TableBodySkeleton() {
  return (
    <>
      <TableRowSkeleton />
      <TableRowSkeleton />
      <TableRowSkeleton />
      {/* <TableRowSkeleton />
      <TableRowSkeleton />
      <TableRowSkeleton />
      <TableRowSkeleton />
      <TableRowSkeleton />
      <TableRowSkeleton /> */}
    </>
  );
}

export default TableBodySkeleton;
