import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';

import { store, persistor } from "./redux/store";
import { Provider } from 'react-redux';
import { PersistGate } from "redux-persist/integration/react";

ReactDOM.render(
  <HelmetProvider>
    <Provider store={store}> {/* Wrap the entire application with Provider */}
      <PersistGate persistor={persistor}>
        <SidebarProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </SidebarProvider>
      </PersistGate>
    </Provider>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
