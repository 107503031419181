import axios from 'axios';
import authHeader from '../utils/auth-header';

const baseURL = process.env.REACT_APP_BACKEND_SERVER;
const originURL = process.env.REACT_APP_API_ORIGIN;

const getAllEvents = async () => {
  const response = await axios({
    method: 'get',
    url: `${baseURL}/ppv/manager/events`,
    headers: authHeader()
  });
  return response.data;
};

const getEventByEventId = async (eventId: string) => {
  const response = await axios({
    method: 'get',
    url: `${baseURL}/ppv/manager/event/${eventId}`,
    headers: authHeader()
  });
  return response;
};

const getTransactionsByEventId = async (eventId: string) => {
  const response = await axios({
    method: 'get',
    url: `${baseURL}/ppv/events/${eventId}/transactions`,
    headers: authHeader()
  });
  return response;
};

const getTransactions = async (UserId: string) => {
  const response = await axios({
    method: 'get',
    url: `${baseURL}/ppv/manager/${UserId}/transactions`,
    headers: authHeader()
  });
  return response;
};

const EventService = {
  getAllEvents,
  getEventByEventId,
  getTransactionsByEventId,
  getTransactions
};

export default EventService;
